
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































.duo-picture {
  @include mq(l) {
    display: flex;
    gap: col(1, 10);

    &.is-reversed {
      flex-direction: row-reverse;
    }
  }
}

.duo-picture__picture {
  position: relative;
  overflow: hidden;
  aspect-ratio: 3/2;
  border-radius: 0.8rem;

  @include mq(l) {
    flex: 1;
    aspect-ratio: auto;

    &.picture.is-cover ::v-deep img {
      position: static;
    }
  }
}

.duo-picture__text {
  margin-top: $spacing;

  @include mq(l) {
    flex: 1;
    margin-top: 0;
  }
}
